<template>
  <div class="betaeditor-panel">
    <a-modal title="Pridanie obsahu" width="60%" style="max-width:920px"  class="builder-content" centered v-model="visible" @ok="handleOk">
      <a-tabs  defaultActiveKey="tempcat:0" :tabPosition="mode" @prevClick="callback" @nextClick="callback">
          <a-tab-pane :tab="cat.title" :key="'tempcat:' + iin" v-for="(cat,iin) in taxonomy">
             <a-radio-group buttonStyle="solid" v-model="newComponent">
                  <template  v-for="(dat,i) in templates">
                  <div class="item" :key="i" v-if="settings.categories[i] === cat.id">
                      <a-radio-button :value="i" class="p-2" v-if="i.includes('view') && !disabled || !i.includes('view')">
                          <i class="fal fa-list-alt" style="position:absolute;z-index:99;right:5px;color:#eaeaea;" v-if="i.includes('view')"></i>
                          <div class="icon-image" v-if="settings.icons[i] && settings.icons[i]['uri'].includes('svg')">
                      <img :src="settings.icons[i]['uri']">
                        </div>
                          <h4>{{settings.titles[i]}}</h4>
                          <span class="text-muted d-table" style="opacity:.6;" v-if="settings.descriptions[i]">{{settings.descriptions[i]}}</span>
                      </a-radio-button>
                      <div v-if="settings.icons[i] && !settings.icons[i]['uri'].includes('svg')" class="hidden-image">
                          <img :src="settings.icons[i]['uri']">
                      </div>
                  </div>
                  </template>
              </a-radio-group>
          </a-tab-pane>
      </a-tabs>

      </a-modal>
      <a-modal v-if="showImportModal" v-model="showImportModal" @ok="crawlPage">
        URL produktu: <a-input v-model="crawlUrl"></a-input>
      </a-modal>
      <a-modal v-model="openCopy" style="max-width:900px" contentClassName="manager"   @ok="openCopy = false" @cancel="openCopy = false" title="Export" >
          <a-alert
                  :message="infotext"
                  type="warning"
                  closable
                  class="mb-2"
                  v-if="infotext"
                  @close="infotext = ''"
          />        <a-textarea v-model="tocopy" id="text-box" class="mb-2" style="min-height:250px;"></a-textarea>
          <a-button type="secondary" id="bo-box" class=" mb-2" size="small" @click="copyToclipboard"><i class="fad fa-clipboard pr-1"></i> Kopírovať</a-button>

      </a-modal>
      <a-modal v-model="openImport" style="max-width:900px" class="builder-modal" contentClassName="manager" @okText="Importovať" @ok="importOK" @cancel="openImport = false" title="Export" >
          <a-alert
                  :message="infotext"
                  type="warning"
                  closable
                  class="mb-2"
                  v-if="infotext"
                  @close="infotext = ''"
          />        <a-textarea v-model="tocopy" id="text-box" class="mb-2" style="min-height:250px;"></a-textarea>
             </a-modal>
      <a-row :gutter="16">
        <a-col :span="5" >
          <div class="left-sidebar">
              <div class="schema-holder">
              <div class="schema-header"><h2>Štruktúra</h2> <a-dropdown :trigger="['click']"><a class="treedots"><i class="fas fa-ellipsis-h"></i></a>
                <a-menu slot="overlay">
                  <a-menu-item key="11" @click="showImportModal = true">Nahrať detaily</a-menu-item>
                              <a-menu-item key="12" @click="exportBuilder">Export</a-menu-item>
                              <a-menu-item key="13" @click="importBuilder">Import</a-menu-item>
                              <a-menu-item key="12" @click="emptyBuilder">Vyprádzniť</a-menu-item>
                          </a-menu>
                </a-dropdown></div>


              <draggable @change="updateBuilder" v-bind="dragOptions" v-model="newcomponents" group="newcomponents" @start="drag=true" @end="drag=false"   handle=".handle">
                   <template v-for="(child, index) in newcomponents">
                      <a-dropdown @change="onChangeList1" :trigger="['contextmenu']"  :key="'::' + index + child.component">
                      <div v-if="child" :class="[{ 'hideimages' : !showImages},{'notshown': child.data.hide},{'transferdata': child.data.transferdata},'element','handle']">
                        <span :class="['tt',child.component.includes('view') ? 'view-item' : '']"><img class="caticon" v-if="taxops[settings.categories[child.component]]" :src="taxops[settings.categories[child.component]].uri"><template v-if="settings.titles[child.component]">{{settings.titles[child.component]}}</template><template v-else>{{ child.component }}</template> <template v-if="child.data.hide" ><span class="fs-10 float-right o-50"><i class="far fa-eye-slash"></i></span></template></span></div>
                          <a-menu slot="overlay">
                              <a-menu-item key="2" @click="duplicateElements(child)">Duplikovať</a-menu-item>
                              <a-menu-item key="3" @click="exportElement(child)">Kopírovať</a-menu-item>
                              <a-menu-item key="3" @click="showhide(child)"><template v-if="child.data.hide">Zobraziť</template><template v-else>Schovať</template></a-menu-item>
                              <a-menu-item key="4" @click="deleteElement(index,child.component)">Zmazať</a-menu-item>
                              <a-menu-item key="5" @click="importBuilder">Importovať</a-menu-item>
                          </a-menu>
                      </a-dropdown>
                  </template>

          </draggable>

 </div>
              <div class="schema-betaitems">
                <div class="schema-header"><h2>Elementy</h2> </div>
  <a-tabs size="small" defaultActiveKey="tempcat:0" tabPosition="top" @prevClick="callback" @nextClick="callback">
          <a-tab-pane :tab="cat.title" :key="'tempcat:' + iin" v-for="(cat,iin) in taxonomy">
             <a-radio-group buttonStyle="solid" v-model="newComponent">
                  <template  v-for="(dat,i) in templates">
                  <template v-if="settings.categories[i] === cat.id && settings.titles[i]">
                    <draggable v-model="list" :key="i" :group="{ name: 'newcomponents', pull: 'clone', put: false }" @change="onChangeList1"  @start="drag=true" @end="(e,f,g) => dropit(i,e)"  v-bind="dragOptions" >
                      <transition-group>
                        <a-radio-button  :key="i"  :value="i" :class="['p-2',i.includes('view') ? 'view-item' : '']" v-if="i.includes('view') && !disabled || !i.includes('view')">
                          <span>{{settings.titles[i]}}</span>
                          <span class="text-muted d-table" style="opacity:.6;" v-if="settings.descriptions[i]">{{settings.descriptions[i]}}</span>
                      </a-radio-button>
                      </transition-group>
                    </draggable>
                  </template>
                  </template>
              </a-radio-group>
          </a-tab-pane>
      </a-tabs></div></div>
</a-col>
<a-col :span="19" class="betaeditor-edit" v-if="!loading">
  <a-tabs position="top" v-model="selectedTab" @change="changedTab">
    <a-tab-pane tab="Editácia" key="edit">
<template v-for="(builderdata, idndex) in newcomponents">
<div :class="[{ 'hideimages' : !showImages},{'notshown': builderdata.data.hide},{'transferdata': builderdata.data.transferdata},'component']" :key="builderdata.id">
  <h3 :class="[templates[builderdata.component] ? '':'mb-0']"><template v-if="settings.titles[builderdata.component]">{{settings.titles[builderdata.component]}}</template><template v-else>{{ builderdata.component }}</template> <span class=" float-right text-right fs-12 "><span class="text-muted o-50 mr-3">{{ builderdata.component }}</span><a-button type="secondary" style="margin-top:-5px;" title="Odstrániť element" @click="deleteElement(idndex,builderdata.component)">x</a-button></span></h3>
  <template v-if="templates[builderdata.component]"><div  class="betaeditor-content">

    <template v-if="templates[builderdata.component].vars">
                  <div :key="i" v-for="(dat,i) in templates[builderdata.component].vars">
                      <div class="form-group has-label">
                          <label class="form-control-label" v-if="settings.fields[builderdata.component]">
                              <span v-if="settings.fields[builderdata.component][dat]">{{settings.fields[builderdata.component][dat].name}}</span><span v-else>{{dat}}</span></label>
                          <label class="form-control-label" v-else>{{dat}}</label>
                          <div v-if="dat.toLowerCase().indexOf('file') === 0">
                         <upload :data="builderdata.data[dat]" :dat="dat" multiple="false" :editid="builderdata.id" @changed="(e) => onChildChange(e,dat,idndex)"></upload>
                      </div>
                      <div v-else-if="dat.toLowerCase().indexOf('image') === 0">

                         <image-upload :data="builderdata.data[dat]" :key="refreshitem" :dat="dat" :editid="builderdata.id" @changed="(e) => onChildChange(e,dat,idndex)"></image-upload>
                      </div>
                      <div style="margin-bottom:20px" v-else-if="dat.toLowerCase().indexOf('text') === 0 || dat.toLowerCase().indexOf('description') === 0 || dat.toLowerCase().indexOf('longtext') === 0">
                           <div id="toolbar-container"></div>
      <ckeditor :editor="editor" v-model="builderdata.data[dat]" @input="updateBuilder" :config="editorConfig"  />
                      </div>
                          <div v-else-if="settings.fields[builderdata.component][dat] && settings.fields[builderdata.component][dat].type === 'longtext' ">
                              <div id="toolbar-container"></div>
                              <ckeditor :editor="editor" v-model="builderdata.data[dat]" @input="updateBuilder" :config="editorConfig"></ckeditor>
                          </div>
                      <div v-else-if="dat.toLowerCase().indexOf('formpicker') === 0">
                          <a-select class="w-100"  mode="single" v-model="builderdata.data[dat]" placeholder="Vyberte si z možností">
                              <a-select-option v-for="op in forms" :key="op.id">{{op.title}}</a-select-option>
                          </a-select>
                      </div>
                      <div v-else>
                          <a-input class="form-control" :placeholder="dat" @change="(e) => onChildChange(e.target.value,dat,idndex)" v-model="builderdata.data[dat]" />
                      </div> </div>
                  </div>


                </template>
                      <div v-if="Object.keys(templates[builderdata.component].itemFields).length !== 0">
                         <div v-if="templates[builderdata.component].itemFields[0] === 'item.image' && templates[builderdata.component].itemFields.length === 1">
                             <gallery :styles="styles" :id="builderdata.id" :data="builderdata.data.items[templates[builderdata.component].itemFields[0]]" @changed="(e) => onChildChangeGallery(e,'items',templates[builderdata.component].itemFields[0])"></gallery>
                          </div>
                              <div v-else>
                          <multiple-items :fieldsettings="settings.fields[builderdata.component]" :datainput="builderdata.data.items" v-if="builderdata.component" :items="templates[builderdata.component].itemFields" :editid="builderdata.id" @changed="(e) => onChildChange(e,'items',idndex)"/>
                          </div>
                      </div>
                      <div v-if="templates[builderdata.component].type === 'view'">
                          <view-editor :url="url" :data="builderdata.data.view" :disabled="disabled" :nodespecific="builderdata.data.view.settings.contentType === ct" :selectedCT="ct"  :vocabulary="vc" :key="'view:' + builderdata.id" :elements="templates[builderdata.component].viewFields"  :fields="settings.fields[builderdata.component]" :editid="builderdata.id" @changed="(e) => onChildChange(e,'view',idndex)"></view-editor>
                      </div>

                      <span v-if="settings.fieldDefinitions[builderdata.component]">
                    <div class="form-group has-label" :key="idnex + dat + aef" v-for="(setupField,aef) in settings.fieldDefinitions[builderdata.component].twigOptions">
                        <label class="form-control-label">{{settings.setupOptions[builderdata.component][aef].name}}</label>

                        <a-select class="w-100"  :defaultValue="builderdata.blockSettings ? builderdata.blockSettings[aef] : ''" @change="(e) => handleSetup(e,aef,idndex)">
                            <a-select-option v-for="fiel in setupField" :key="fiel"><template v-if="settings.setupOptions[builderdata.component][fiel]">{{settings.setupOptions[builderdata.component][fiel].name}}</template><template v-else>{{ fiel }}</template></a-select-option>
                        </a-select>


                    </div></span>
                    </div>
                </template>
                    </div>
        </template>
        </a-tab-pane>
        <a-tab-pane key="preview" tab="Ukážka">
  <template>
    <iframe v-if="page" class="page-holder" :srcdoc="page" style="width:100%;height:100vh;overflow-y:auto;"></iframe>
    <div class="spinner" v-else ><a-spin size="large" /></div>
</template>
</a-tab-pane>
</a-tabs>
  </a-col>
  <a-col :span="19" class="betaeditor-edit text-center p-4 gb-grey" v-else><a-spin tip="preberám externý obsah ..."></a-spin> </a-col>
  </a-row>
  </div>
</template>

<script>
  import {getURL} from "../../api";
  import {authHeader} from "../../_helpers";
  import ARadioGroup from "ant-design-vue/es/radio/Group";
  import Image from "./Image";
  import Upload from "./Upload";
  import MultipleItems from "./multi";
  import ViewEditor from "./View";
  import ARadioButton from "ant-design-vue/es/radio/RadioButton";
  import {dataService} from "../../_services";
  import ATabs from "ant-design-vue/es/tabs";
  import draggable from "vuedraggable";
  import gallery from './Gallery'
    import {Dropdown} from 'ant-design-vue';
     import {IMG_URL} from "../../api";

  import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
  import '@ckeditor/ckeditor5-build-classic/build/translations/sk';
  import ATextarea from "ant-design-vue/es/input/TextArea";


  const formItemLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 8 },
  };
  const formTailLayout = {
      labelCol: { span: 4 },
      wrapperCol: { span: 8, offset: 4 },
  };
  export default {
      name: "Builder",
      components: {
          ATextarea, draggable,
            "a-dropdown": Dropdown,MultipleItems, gallery, ATabs , 'view-editor': ViewEditor, 'image-upload' : Image, 'upload' : Upload, ARadioButton, ARadioGroup},
      props: ["data","values","ct","vc","url","disabled","id"],
      data() {
          return {
              size: 'small',
              newComponent: null,
              componentId: 0,
              sendfieldsData:{},
              mode: "left",
              forms:[],
              templates: [],
              formItemLayout,
              openCopy:false,
              formTailLayout,
              widthmodal:800,
              styles:[],
              openImport: false,
              list:[],
              page:false,
              infotext:"",
              selectedTab:'edit',
              tocopy: "",
              IMG_URL,
              showImages: true,
              crawlUrl:"",
              templatesCat: [],
              taxonomy: {},
              settings:{},
              loading:false,
              editor: ClassicEditor,
              editorConfig: {
                      heading: {
                      options: [
                          { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
                          { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
                          { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
                          { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
                          {
                              model: 'headingFancy',
                              view: {
                                  name: 'h3',
                                  classes: 'fancy'
                              },

                              // It needs to be converted before the standard 'heading2'.
                              converterPriority: 'high'
                          }
                      ]
                  },
                  toolbar: [ 'heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'insertTable', '|', 'imageUpload', 'mediaEmbed', '|', 'undo', 'redo'  ],
                  table: {
                      toolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
                  },
                  language: 'sk',
              },
              imagetoshow: {},
              showImportModal:false,
              showImportModalImages:false,
              datatosave: {},
              refreshitem:0,
              taxops:{},
              blockSettings:{},
              gurl: getURL(),
              head: authHeader(),
              editting: {'id': null,'component':null},
              newcomponents: [],
              visible: false,
              editVisible: false
          };
      },
      methods: {
          toggleThumb() {
              this.showImages = !this.showImages;
          },
          changedTab()
          {
this.page = false;
if(this.selectedTab == 'preview')
this.updateBuilder();
          },
          onReady()
          {
var self = this;
              setTimeout(function(){ self.widthmodal = 801; }, 150);
          },
          showhide(child)
          {
child.data.hide = !child.data.hide;
this.$emit('changed', this.newcomponents);
          },
          callback() {

          },
          onChangeList1(event) {
      // Check if an item is dragged from list1 to list2
      if (event.moved) {
        // Perform your custom action here instead of placing the item in list2
        console.log('Item dragged from list1 to list2:', event);
        // For example, you can remove the item from list1

        // Or perform any other custom action
      }
    },
          showModal() {
              this.visible = true;
          },
          showTemplate()
            {
dataService.axiosPost("showpage", {builder: this.values, id:this.id}).then(results => {
this.page = results.data.data[0];
    });
     },
     checkMove(event, oldevent)
     {
     },
          crawlPage()
          {
            this.showImportModal = false;
            this.loading = true;
            dataService.axiosPost('crawlpage',{url:this.crawlUrl}).then(results => {


var dat = JSON.parse(results.data[0]);


dat.forEach(obj => {
this.newcomponents.push(obj);
});
              this.updateBuilder();
this.loading = false;
          });
          },
          updateBuilder() {

this.page = false;
dataService.axiosPost("showpage", {builder: this.newcomponents, id: this.id}).then(results => {
this.page = results.data.data[0];
    });
              this.$emit('changed', this.newcomponents);
          },
          handleSetup(e,setu,dat)
          {

              if (!this.newcomponents[dat].hasOwnProperty('blockSettings') || this.isArray(this.newcomponents[dat].blockSettings))
              {
                this.newcomponents[dat].blockSettings = {};
              }
              this.newcomponents[dat].blockSettings[setu] = e;

this.$emit('changed', this.newcomponents);
          },
          deleteElement(id) {
            //  this.$delete(this.newcomponents, id);
              this.newcomponents.splice(id, 1);
              this.$emit('changed', this.newcomponents);
              this.updateBuilder();
          },
          editModal(id,component) {

              this.editting = {'id': id,'component':component};

              if(this.newcomponents[id].data.view)
              {
                    this.sendfieldsData = this.newcomponents[id].data;

              }
              else
              {this.sendfieldsData = {'defaultstyle':'','hide' : false,'transferdata' : false,'blockSettings': {},'urlParameter' : false,'view': {'settings': {'contentType' : '','templates' : {}},'items':{}}};}

              if (this.sendfieldsData.items === undefined || this.sendfieldsData.items.length == 0) {
                  {
                      this.sendfieldsData.items = {};
                  }
              }
this.datatosave = this.sendfieldsData;
              this.editVisible = true;
          },
          onChildChangeGallery(value,slug,itemslug)
          {

              if (!this.datatosave[slug].hasOwnProperty(itemslug))
              {
                  this.datatosave[slug][itemslug] = {};
              }
              this.datatosave[slug][itemslug] = value;
          },
          onChildChange(value,slug,i) {

              this.datatosave[slug] = value;

this.newcomponents[i]['data'][slug] = value;

this.$emit('changed', this.newcomponents);
console.log(this.newcomponents);

//this.refreshitem = this.refreshitem + 1;

          },
          exportElement(a)
          {
              let cloned = Object.assign({}, a);
              this.componentId = this.componentId + 1;
              cloned.id = this.componentId;
              var pa = this;
              let aarr = [];
              aarr.push(cloned);
              navigator.clipboard.writeText(JSON.stringify(aarr)).then(function() {
                  pa.infotext = "skopírované";
              }, function() {
                  pa.infotext = "neskopírované";
              });
          },
          duplicateElements(a) {
             let cloned = Object.assign({}, a);

            this.componentId = this.componentId + 1;
             cloned.id = this.componentId;

             let aarr = [];

             aarr.push(cloned);

             this.tocopy = JSON.stringify(aarr);
             this.importOK();
             this.updateBuilder();

          },
          closeEdit()
          { this.widthmodal = 800;
              this.editting = {};
          },
        isJson(str) {
      try {
          JSON.parse(str);
      } catch (e) {
          return false;
      }
      return true;
  },
          handleEdit(data,id) {

             this.newcomponents[id][data] = data;
              this.$emit('changed', this.newcomponents);

              this.editting = {};
          },
          exportBuilder()
          {
              this.openCopy = true;
           var text =   JSON.stringify(this.newcomponents);
           this.tocopy = text;

          },
          importOK()
          {
              this.openImport = false;

              let pa = this;
                  Object.keys(JSON.parse(pa.tocopy)).forEach(function(key) {
                      pa.newcomponents.push(JSON.parse(pa.tocopy)[key]);
                  });
              this.updateBuilder();
          },
          emptyBuilder()
          {
          this.newcomponents = [];
          },
          importBuilder()
          {

               this.tocopy = "";

                 navigator.clipboard.readText()
                  .then(text => {
                      this.tocopy =  text;
                      this.importOK();
                  });

               this.infotext = "";


          },
          copyToclipboard()
          {
var pa = this;
              navigator.clipboard.writeText(this.tocopy).then(function() {
                  pa.infotext = "skopírované";
          }, function() {
                  pa.infotext = "neskopírované";
          });
          },
  array_values(arrayObj) {
  let tempObj = {};
  Object.keys(arrayObj).forEach((prop) => {
    if (arrayObj[prop]) { tempObj[prop] = arrayObj[prop]; }
  });
  return tempObj;
},
          dropit(e,f)
          {
                this.componentId = this.componentId + 1;
                this.newcomponents.splice(f.newIndex,0,{'id' : this.componentId, 'blockSettings':{}, 'component' : e,'data': {'view': {'settings':{'contentType' : '','templates' : {}}},'items':{}}});

                this.newComponent = null;
              //  this.updateBuilder();
            //    this.$emit('changed', this.newcomponents);


          },
          isArray(value) {
      return Array.isArray(value);
    },
          handleOk() {

              if(this.newComponent){
              this.componentId = this.componentId + 1;
              this.newcomponents.push({'id' : this.componentId, 'blockSettings':{}, 'component' : this.newComponent,'data': {'view': {'settings':{'contentType' : '','templates' : {}}},'items':{}}});
              this.newComponent = null;
              this.visible = false;
              this.$emit('changed', this.newcomponents);

              }
          },
      },
      computed: {
          dragOptions() {
              return {
                  animation: 0,
                  disabled: false,
                  ghostClass: "ghost"
              };
          }
      },
      watch: {
      },
      mounted() {
          dataService.axiosFetch('taxonomyitems',"theme_category").then(results => {
              this.taxonomy = results;



    var roleops = {};
    var tm = "";
    results.forEach(obj => {
  if(obj.image)
  {
    tm =  JSON.parse(obj.image);
  tm.uri = IMG_URL + tm.uri;
          roleops[obj.id] =  tm;
          }
    });
this.taxops = roleops;

          });


          dataService.axiosFetch('forms').then(results => {
              this.forms = results;
          });
          dataService.axiosFetch('templates').then(results => {
              this.templates = results.components;
              this.templatesCat = results.categories;

              this.settings = results.settings;

          });
         if(this.values.length > 0)
         {
         this.newcomponents = JSON.parse(this.values);
this.newcomponents.forEach(obj => {
                 if(obj.id > this.componentId)
                 this.componentId = obj.id;
             })
           }
         else
         {
          this.newcomponents = [];
          this.componentId = 0;
         }
      }
  }
</script>


<style>
.builder-content .item{
  display:block;width:97%;
  margin:0.5%;
  line-height:40px;
  max-width:550px;
}
.gallery-images
{width:100%;display:inline-block;}
.gallery-image-holder .title
{position:absolute;width:100%;text-align:left;font-size:12px;color:#fff;font-weight:bold;
  max-width:200px;overflow:hidden;bottom:15px;left:5px;}
.gallery-image-holder .alttag
{position:absolute;width:100%;text-align:left;font-size:12px;color:#fff;
  max-width:200px;overflow:hidden;bottom:3px;left:5px;}
.image-setup
{
  display:block;
  width:100%;
  bottom:-2px;
  right:-2px;
  position:absolute;
  z-index:9;
  text-align:right;
}
.ant-radio-group
{width:100%;}
.builder-content .ant-modal-content .ant-radio-group
{overflow-y:auto;
max-height:500px;}
.element.hideimages
{
padding:0;
  border-bottom:1px solid #fff;
  border-radius:0;
}
.builder-modal .form-group
{margin-bottom:1rem}
.builder-content .item h4
{font-size:14px;margin:0;}
.manager
{width:800px}
.top-holder
{width:100%; padding:15px   }
.element-image
{width:100%; border-bottom:1px solid #eceff3; padding-bottom:10px;margin-bottom:10px;}
.ant-form-item
{margin-bottom:0}
.ant-radio-button-wrapper:hover
{background-color:#ecf6ff;}

.builder-content  .ant-radio-button-wrapper img
{height:100%;width:100%;display:block;}
.notshown span.tt
{opacity:.5}
.transferdata span.tt
{opacity:.5;
border: 1px dashed #ddd;
background-color:#ffffff}
.icon,.handle
{float:left;}
.icon
{margin-right:15px;
  border: 5px solid transparent;
  border-top: 0px;}
.builder-content .ant-radio-button-wrapper::before
{display:none!important}
.builder-content .ant-radio-button-wrapper
{padding:0;margin-bottom:5px; margin-right:.5%;
  width:100%;
  line-height:initial;
  text-align:left;
  overflow:hidden;
  height:auto;
  border:1px solid #d9d9d9;
  display: block;  }
.builder-content .ant-radio-button-wrapper-checked h4,
.builder-content .ant-radio-button-wrapper-checked .text-muted
{
  color:#fff!important;
}
.builder-content .icon-image
{float:left;
  width:50px; margin-right:5px;
height:30px;}
.builder-content .icon-image img
{  height:36px;}
.builder-content .item
{position:relative;}
.item:hover .hidden-image
{display:block;}
.hidden-image
{ display:none;
position:absolute;z-index:999;top:0;right:0;width:150px;}
.hidden-image img
{width:100%;}
.builder-modal .ant-modal-body .ant-tabs-tabpane-inactive
{display:none}
.handle:hover
{cursor:grab}
  .toggleShower
  {float:right;margin-top:-30px;}
.edit-button
{
  float:right;
  margin:0;
  margin-left:2px;
  line-height:45px;
  border-radius:0px;
  border: 0;
  color: #003366;
  background-color:transparent;
  height: 45px;
  transition: all 0s;

}
.element:hover .tt
{background-color: #f9f9f9;}
.main-button
{margin-top:20px;}
.main-button i
{position:relative;top:-5px;}
.edit-button:hover
{color: #0b80e4;
  text-shadow: 0px 0px 1px #0089ff;}
.edit-button em
{display:inherit;width:100%;clear:both; line-height:13px;font-size:11px;padding-top:5px;font-style: normal}
.edit-button >>> i
{display:inherit;margin-bottom:0;}
.ant-radio-button-wrapper img
  {width:400px}

.flip-list-move {
  transition: transform 0.5s;
}
.no-move {
  transition: transform 0s;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.tt .icon img
{max-width:100%;}
.list-group {
  min-height: 20px;
}
.list-group-item {
  cursor: move;
}
.list-group-item i {
  cursor: pointer;
}

.ck.ck-toolbar, .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused)
{border-color: #d7dae8 !important}

@media screen and (max-width: 560px) {
  .edit-button em {
      display: none;
  }
  .edit-button
  {padding:3px}
}
@media screen and (max-width: 400px) {
  .tt .icon {
      display: none;
  }
  .element span.tt
  {font-size:12px;}
  .tt .handle
  {padding:5px;
  }}
</style>
